<template>
  <div>
    <b-modal
      id="modal-edit-mat"
      ref="modal"
      header-class="headerModal"
      centered
      :title="addEdit ? $t('AddMaterial'): $t('EditMaterial')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="`${$t('Description')}:`"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="$v.form.description.$model"
            :state="validateState('description')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('MeasurementType')}:`"
        >
          <div class="flex radio-form">
            <div class="flex aic">
              <input
                id="one"
                v-model="form.measurementType"
                type="radio"
                value="Meter"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="one">{{ $t('Meter') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="one"
                v-model="form.measurementType"
                type="radio"
                value="Meter_Square"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="one">{{ $t('Meter_Square') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="two"
                v-model="form.measurementType"
                type="radio"
                value="Part"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="two">{{ $t('Part') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="three"
                v-model="form.measurementType"
                type="radio"
                value="2"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="three">{{ $t('Package') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="four"
                v-model="form.measurementType"
                type="radio"
                value="3"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="four">{{ $t('BeadedStrand') }}</label>
            </div>
          </div>
          <div class="flex radio-form">
            <div class="flex aic">
              <input
                id="five"
                v-model="form.measurementType"
                type="radio"
                value="4"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="five">{{ $t('Set') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="six"
                v-model="form.measurementType"
                type="radio"
                value="5"
                :disabled="rowsLengthItem >= 1"
              >
              <label for="six">{{ $t('Pair') }}</label>
            </div>
          </div>
          <p
            v-if="rowsLengthItem >= 1"
            style="color: red"
            class="small"
          >
            {{ $t('ChangeMeasure') }}
          </p>
        </b-form-group>
        <b-form-group />

      </b-form>
      <div class="buttonsEverywhere">
        <button
          v-if="addEdit"
          type="button"
          variant="none"
          class="buttonSubmit"

          @click="onSubmitAdd"
        >
          {{ $t('Submit') }}
        </button>
        <button
          v-else
          type="button"
          variant="primary"
          class="buttonSubmit"
          @click="onSubmitEdit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editM: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    addEdit: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    rowsLengthItem: {
      type: [Number, String],
    },
    // eslint-disable-next-line vue/require-default-prop
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
        // minLength: minLength(3),
      },
      description: {
        // required,
        // minLength: minLength(3),
      },
    },
  },

  mounted() {
    this.form = this.editM
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmitEdit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('materialsEdit', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },

    onSubmitAdd() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // alert('Form submitted!');
      this.$emit('materialsAdd', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      this.form.name = ''
      this.form.description = ''
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    display: flex;
    flex-direction: column;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
   input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
</style>
